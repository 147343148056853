import React, { useEffect, useState } from 'react'

import Layout from '../layouts'
import * as Libs from 'libs'

const NotFoundPage = () => {
  useEffect(() => {
    Libs.Common.Config.excuteTransition()
  }, [])

  return (
    <Layout>
      <section className={'project-container'} data-type={'page'}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.aaa</p>
      </section>
    </Layout>
  )
}

export default NotFoundPage
